<template>
  <div>
    <div v-if="isLoading">
      <!-- <OtherLoading /> -->
    </div>
    <div v-else>
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="mr-sm-4 header-tablepage">RICH MENU DETAIL</h1>
          </b-col>
        </b-row>

        <div
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <div class="px-4 px-sm-5 py-4">
            <div>
              <HeaderLine
                text="General Information"
                class="mb-3"
                :isLine="false"
              />
            </div>
            <div class="">
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.name"
                    isRequired
                    textFloat="Name"
                    placeholder="Name"
                    type="text"
                    :isValidate="$v.form.name.$error"
                    :v="$v.form.name"
                    :disabled="id != 0"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.richMenuAlias"
                    textFloat="Alias"
                    placeholder="Alias"
                    type="text"
                    :isValidate="$v.form.richMenuAlias.$error"
                    :v="$v.form.richMenuAlias"
                    :disabled="id != 0"
                  />
                </b-col>

                <b-col>
                  <b-form-checkbox
                    :id="`checkbox-default`"
                    :name="`checkbox-default`"
                    v-model="form.isMember"
                    :disabled="id != 0"
                  >
                    Member
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
            <hr />
            <HeaderLine text="Template" class="my-3" :isLine="false" />

            <Template ref="template" class="" :data="form" :id="id"></Template>

            <b-row class="mt-5">
              <b-col md="6">
                <b-button to="/rich-menu" class="btn-details-set">{{
                  this.id == 0 ? "CANCEL" : "BACK"
                }}</b-button>
              </b-col>
              <b-col md="6" class="text-sm-right" v-if="this.id == 0">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm()"
                >
                  Save
                </button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </div>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText.vue";
import Template from "./components/Template.vue";
import { required } from "vuelidate/lib/validators";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import HeaderLine from "@/components/HeaderLine";

const eng_number = (val) => {
  if (!val) return true;
  if (val.match("[a-z\s0-9-]+$")) {
    return true;
  }
  return false;
};
export default {
  components: {
    ModalAlertError,
    InputText,
    Template,
    ModalAlert,
    HeaderLine,
  },

  data() {
    return {
      isLoading: true,
      form: {
        name: "",
        richMenuAlias: "",
        isMember: false,
      },
      id: this.$route.params.id,
      tabIndex: 0,
      templateOptions: [],
      modalMessage: "",
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        richMenuAlias: {
          eng_number,
        },
      },
    };
  },
  async created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;

      if (this.id > 0) {
        let resData = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/lineRich/${this.id}`,
          null,
          this.$headers,
          null
        );
        if (resData.result == 1) {
          this.form = resData.detail;
        }
      }

      this.isLoading = false;
    },
    async saveForm() {
      this.$refs.template.$v.$touch();
      this.$v.form.$touch();

      if (this.$refs.template.$v.$error || this.$v.form.$error) return;
      this.isLoading = true;
      let payload = {
        ...this.$refs.template.form,
        ...this.form,
        chatBarText: this.$refs.template.form.chatBarText,
      };
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/lineRich/CreateRichMenu`,
        null,
        this.$headers,
        payload
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert
          .show()
          .then(() => this.$router.push("/rich-menu"));
      } else {
        this.$refs.modalAlertError.show();
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
</style>
